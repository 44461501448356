<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },
        displayName: {
            required: true,
            type: String,
        },
        value: {

        },
        type: {
            default: 'text',
        },
        error: [],

        hideLabel: {
            type: Boolean,
            default: false,
        },

        readOnly: {
            type: Boolean,
            default: false,
        },

        onEnter: {

        },
        append: {

        },
        max: {

        }
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {
        handleInput(e) {
            this.$emit('input', e.target.value);
        },

        handleEnter() {
            if (this.onEnter) {
                this.onEnter();
            }
        }
    }
};
</script>

<template>
    <div class="form-group">
        <div>
            <label :for="id" v-if="hideLabel === false">{{ displayName }}</label>
            <div class="input-group mb-3" v-if="append">
                <input :type="type" class="form-control" :placeholder="displayName" :value="value" @input="handleInput" :max="max" v-on:keyup.enter="handleEnter" :disabled="readOnly" :style="readOnly ? { opacity: '0.6' } : {}">
                <div class="input-group-append">
                    <span class="input-group-text">{{ append }}</span>
                </div>
            </div>
            <div v-else>
                <input :type="type" class="form-control" :placeholder="displayName" :value="value" @input="handleInput" :max="max"
                    v-if="type !== 'textarea'" v-on:keyup.enter="handleEnter" :disabled="readOnly" :style="readOnly ? { opacity: '0.6' } : {}">
                <textarea class="form-control" rows="5" :placeholder="displayName" :value="value" @input="handleInput"
                    v-on:keyup.enter="handleEnter" v-else :disabled="readOnly" :style="readOnly ? { opacity: '0.6' } : {}"></textarea>
            </div>
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="error && error.length > 0">
            {{ error[0] }}
        </div>
    </div>
</template>
